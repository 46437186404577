<template>
  <div class="m-card3" v-if="list&&list.length">
    <div class="mc-top">
      <div class="mc-top-title">
        <span class="mc-top-text mc-top-txt s-bold">供需信息</span>
      </div>
      <div @click="moreClick" v-if="showMore" class="batch">
        换一批
      </div>
    </div>
    <div style="height: 228px">
      <div class="supply-list" >   
        <div class="supply" v-for="(item) in list" :key="item.id" @click="goQrCode(item.id)">
          <div class="supply_top">
            <span class="span1">{{item.type}}</span>
            <span class="span_name" :title="item.productName">{{item.productName}}</span>
          </div>
          <div class="span2" :title="item.content">{{item.content}}</div>
        </div>
      </div>
    </div>
    <Supplyqrcode ref="showcode"></Supplyqrcode>
  </div>
</template>
 
<script>
import { mapActions,mapGetters} from 'vuex';
import Supplyqrcode from "@components/Supplyqrcode/index.vue";
export default {
  name: "product-list",
  props: {
  },
  data() {
    return {
      list:[],
      totalCount:0,
      pageIndex:1,
      pageSize:2,
      finished:false,
      loading:false,
      showMore:true,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters({
      exhibitionId:'getExhibitionId',
    }),
    exhibitorId() {
      return this.$route.params.id;
    },
  },
  components: {
    Supplyqrcode
  },
  methods: {
    ...mapActions({
      graphqlPost:'graphqlPostByZXS',
    }),
    goQrCode(id) {
      if (id) {
        this.$refs.showcode.open(id);
      }
    },
    async getData(){
      this.loading = true;
      let query = `
        query($query:QueryInput!){
          supplyDemandQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items{
                  content
                  id
                  imgUrls
                  publishId
                  publisherType
                  originName
                  type
                  productName
                }
              }
            }
        }
      `;
      let where={
        "Equal":{
          "n":"publishId",
          "v":this.exhibitorId
        },
        "EqualB":{
          "n":"auditState",
          "v":1
        },
        "Equal3":{
          "n":"publisherType",
          "v": 1
        },
      }
      let order=[
        {
          "N":"topicIndex",
          "v":0
        },  
        {
          "N":"createAt",
          "v":0
        }
      ]
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where:JSON.stringify(where),
            order:JSON.stringify(order)
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(JSON.stringify(data.data.supplyDemandQuery.query));
      this.list=res.items
      // console.log(this.list,'need')
      this.loading=false;
      if (!res.hasNextPage) {
        if(this.pageIndex==1){
          this.showMore=false;
        }
        this.finished = true;
      }
    },
    //点击加载更多
    moreClick(){
      if (this.finished){
        this.pageIndex=1;
        this.finished=false
      }else{
        this.pageIndex += 1;
      }
      this.getData()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.supply-list {
  padding: 20px;
  height: 100%;
  background-color: #fff;
  .supply {
    height: 86px;
    background-color: #f7f7f7;
    padding: 10px;
   
    line-height: 24px;
    margin-bottom: 10px;
    &_top{
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .span1 {
      font-size: 12px;
      background-color: #ffefe5;
      border-radius: 3px;
      color: #ff5e00;
      padding: 4px;
    }
    .span_name{
      margin-left: 10px;
      font-size: 14px;
      color: #333;
    }
    .span2 {
      display: block;
      font-size: 14px;
      color: #333;
       -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      display: -webkit-box;
    }
  }
}
.batch{
	color: #666;
	font-size: 14px;
	margin-right: 20px;
	cursor: pointer;
	user-select: none;
	&:hover{
		@include font_color(#1890ff);
	}
}
</style> 