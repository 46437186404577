<template>
  <div>
    <Modal
      :footer-hide="true"
      :mask-closable="false"
      v-model="showcode"
    >
      <div style="
          width: 486px;
          height: 510px;
          text-align: center;
          padding-top: 85px;
        ">
        <div class="qrcode1" :id="id"></div>

        <div style="font-size: 16px; color: #333; margin-top: 20px">
          <span>使用微信扫一扫 继续交流</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import url from "@/utils/url";

export default {
  name: "index",
  props: {
    url: String,
    id:{
      type:String,
      default:'qrcode1'
    }
  },
  data () {
    return {
      showcode: false,
    };
  },
  created () { },
  methods: {
    open (id) {
      document.getElementById(this.id).innerHTML = "";
      let url = `https://${url.mobile}/needDetails/` + id;  
      let qrcode = new QRCode(this.id, {
        width: 234,
        height: 234,
        text: url, // 二维码内容
        correctLevel: 3,
        // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f',   // 背景色
        // foreground: '#ff0'    // 前景色
      });
      this.showcode = true;

    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.qrcode1 {
  width: 234px;
  height: 234px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
</style>
