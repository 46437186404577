<template>
  <div class="content">
    <div class="head-banner">
      <!-- <div
        class="ex_banner"
        v-if="exInfo.company.banners && exInfo.company.banners.length"
      >
        <gSwiper :list="
            exInfo.company.banners && exInfo.company.banners.length
              ? exInfo.company.banners
              : []
          "></gSwiper> -->
      <!-- <gSwiperCommon :bannerType="'show.page.home.pc'"></gSwiperCommon> -->
      <!-- <img
          src="https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2634306427,1511025022&fm=26&gp=0.jpg"
          
          class="banner"
        />-->
      <!-- </div> -->
      <div class="company-title">
        <div class="company-title-content">
          <img :src="exInfo.company.logo" :class="['logo', {'round': !exhibitorWebLogo }]" v-if="exInfo.company && exInfo.company.logo" v-load="'oPic'" />
          <img :src="exhibitionInfo.logo" :class="['logo', {'round': !exhibitorWebLogo }]" v-else />
          <div class="company-title-center">
            <div class="top">
              <div class="name" v-if="exInfo.company && exInfo.company.name">{{ exInfo.company.name }}</div>
              <div class="address" v-if="exInfo.company">
                <span v-if="exInfo.company.country">{{ exInfo.company.country }} <span v-if="exInfo.company.province">/</span></span>
                <span v-if="exInfo.company.province">{{ exInfo.company.province }} <span v-if="exInfo.company.city">/</span></span>
                <span v-if="exInfo.company.city">{{ exInfo.company.city }} </span>
              </div>
              <div style="display: flex; align-items: center; flex-shrink: 0" v-if="exInfo.continuouNumber">
                <div class="product-renzheng">
                  <div>
                    <img src="@/assets/img/jinpai.png" v-if="exInfo.continuouNumber > 7" />
                    <img src="@/assets/img/yinpai.png" v-else-if="exInfo.continuouNumber < 5" />
                    <img src="@/assets/img/tongpai.png" v-else />
                    <!-- <img src="@/assets/img/jinpai.png"  /> -->
                  </div>
                  <span>连续{{ exInfo.continuouNumber }}年参展</span>
                </div>
              </div>
            </div>
            <div class="center">
              <div class="ellipsis1">
                <template v-if="exInfo.boothInfo">
                  <i class="iconfont icon-dizhi"></i>
                  <span class="addr" @click="ToTour(exInfo)">{{ exInfo.boothInfo }}</span>
                </template>
                <template v-if="exInfo.category">
                  <i class="iconfont icon-biaoqian1"></i>
                  <span>{{ exInfo.category }}</span>
                </template>
              </div>
            </div>
            <div class="bottom">
              <div class="tongji">
                <div style="text-align: center">
                  <div class="span1">{{ exInfo.talkCount !== '-'? wCount(exInfo.talkCount): '-' }}</div>
                  <div class="span2">洽谈</div>
                </div>
                <div class="fenge"></div>
                <div style="text-align: center">
                  <div class="span1">
                    {{exInfo.appointmentNumber !== '-' ? wCount(exInfo.appointmentNumber): '-'}}
                  </div>
                  <div class="span2">预约</div>
                </div>
                <div class="fenge"></div>
                <div style="text-align: center">
                  <div class="span1">{{ exInfo.viewCount !== '-' ? wCount(exInfo.viewCount) : '-' }}</div>
                  <div class="span2">浏览</div>
                </div>
                <div class="fenge"></div>
                <div style="text-align: center">
                  <div class="span1">{{ exInfo.userCount !== '-' ? wCount(exInfo.userCount) : '-' }}</div>
                  <div class="span2">访客</div>
                </div>
                <!-- <div class="fenge"></div>
                <div style="text-align: center">
                  <div class="span1">{{ exInfo.thinkCount !== '-' ? wCount(exInfo.thinkCount) : '-' }}</div>
                  <div class="span2">注册</div>
                </div> -->
                <div class="fenge"></div>
                <div style="text-align: center">
                  <div class="span1">{{ exInfo.productNumber !== '-' ? wCount(exInfo.productNumber) : '-' }}</div>
                  <div class="span2">展品</div>
                </div>
                <div class="fenge"></div>
                <div style="text-align: center">
                  <div class="span1">
                    {{
                    exInfo.activityNumber !== '-' ? wCount(exInfo.activityNumber) : '-'
                    }}
                  </div>
                  <div class="span2">活动</div>
                </div>
              </div>
            </div>
          </div>
          <!--右边点赞-->
          <div class="dianzan">
            <div :class="isFollower ? 'ok' : ''" @click="exFollowerClick" class="icon1 icon_wrap">
              <i class="iconfont icon-love"></i>
              <span>{{ exInfo.followCount }} 人关注</span>
            </div>
            <div :class="isLike ? 'ok' : ''" @click="exLikeClick" class="icon2 icon_wrap">
              <i class="iconfont icon-like"></i>
              <span>{{ exInfo.likeCount }} 人点赞</span>
            </div>
          </div>
          <div class="bottom-btn">
            <div v-if="exInfo.customerServiceIsAppointmentNumber>0" @click="onLineTalk" class="u-btn-zixun">
              <i class="iconfont icon-ziyuan" style="margin-right: 10px"></i>
              <span>在线洽谈</span>
            </div>
            <div v-else class="u-btn-zixun  hui">
              <i class="iconfont icon-ziyuan" style="margin-right: 10px"></i>
              <span>在线洽谈</span>
            </div>
            <div @click="orderOpen" class="u-btn-xunpan">
              <i class="iconfont icon-yuyue1" style="margin-right: 10px"></i>
              <span>预约</span>
            </div>
          </div>
        </div>
      </div>
      <div class="g-layout">
        <div class="g-layout-r" style="margin-left: 0; max-width: 880px">
          <div class="m-card3" v-if="
              exInfo.summaryZh ||
              (exInfo.objectiveTags && exInfo.objectiveTags.length)
            ">
            <div class="mc-top">
              <div class="mc-top-title">
                <span class="mc-top-text mc-top-txt s-bold">展商简介</span>
              </div>
            </div>
            <div class="introduction">
              <div class="introduction-top">
                <p style="white-space: pre-wrap;" v-html="exInfo.summaryZh ? $service.replaceSpeace(exInfo.summaryZh) : '暂无'"></p>
              </div>
              <div class="introduction-bottom" v-if="exInfo.objectiveTags && exInfo.objectiveTags.length">
                <div class="introduction-bottom-category">
                  <div class="top">
                    <span :key="index" v-for="(item, index) in exInfo.objectiveTags">{{ item }}</span>
                  </div>
                  <div class="bottom" v-if="exInfo.objectives">
                    <p>{{ exInfo.objectives }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--热门产品-->
          <proList></proList>
          <!--热门活动-->
          <actList></actList>
          <!--企业风采-->
          <mienList></mienList>
          <!-- 用户评价 -->
          <evaluate :evalCount="exInfo.evaluateCount" :evalTags="exInfo.evaluateTags" v-if="exInfo.evaluateCount > 0"></evaluate>
        </div>
        <div class="g-layout-l" style="margin-left: 10px">
          <!-- 供需详情-->
          <needList></needList>
          <!-- 公司信息-->
          <div class="m-card3" v-if="
              exInfo.company &&
              (exInfo.company.name ||
                exInfo.company.website ||
                exInfo.company.email ||
                exInfo.company.address)
            ">
            <div class="mc-top">
              <div class="mc-top-title">
                <span class="mc-top-text mc-top-txt s-bold">公司信息</span>
              </div>
            </div>
            <div class="company">
              <div class="company-content">
                <div class="company-name">{{ exInfo.company.name }}</div>
                <div class="company-address" v-if="exInfo.company.website">官网：{{ exInfo.company.website }}</div>
                <!-- <div class="company-address" v-if="exInfo.company.email">
                  邮箱：{{ exInfo.company.email }}
                </div>-->
                <div class="company-address" v-if="exInfo.company.address">地址：{{ exInfo.company.address }}</div>
              </div>
            </div>
          </div>
          <!--企业视频-->
          <div class="m-card3" v-if="exInfo.company.videos && exInfo.company.videos.url">
            <div class="mc-top">
              <div class="mc-top-title">
                <span class="mc-top-text mc-top-txt s-bold">企业视频</span>
              </div>
            </div>
            <div>
              <div class="company-video" controls="controls">
                <videoPlayer :cover="exInfo.company.videos.cover" :time="exInfo.company.videos.time" :url="exInfo.company.videos.url"></videoPlayer>
              </div>
            </div>
          </div>
          <!--企业咨询-->
          <div class="m-card3" v-if="newsList.length">
            <div class="mc-top">
              <div class="mc-top-title">
                <span class="mc-top-text mc-top-txt s-bold">企业资讯</span>
              </div>
            </div>
            <div class="news">
              <div class="news-item">
                <div @click="goToNews(newsList[0])" class="news-top">
                  <img :src="newsList[0].covers[0]" class="news-banner" v-if="newsList[0].covers[0]" />
                  <img class="news-banner" src="@/assets/img/empty.png" v-else />
                  <p class="news-top-p">{{newsList[0].title}}</p>
                  <span class="time">{{newsList[0].publishAt}}</span>
                </div>
                <div class="news-bottom" v-if="newsList.length>1">
                  <template v-for="(item,index) in newsList">
                    <div :key="index" @click="goToNews(item)" class="news-list" v-if="index!=0">
                      <img :src="item.covers[0]" v-if="item.covers[0]" />
                      <img src="@/assets/img/empty.png" v-else />
                      <div class="right-box" style="margin-left: 14px">
                        <p>{{item.title}}</p>
                        <div class="time">{{item.publishAt}}</div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <!--二维码-->
          <div class="m-card3">
            <div class="code">
              <div ref="qrCodeUrl" style="width: 220px; height: 220px; margin: 0 auto"></div>
              <span>扫码查看移动端</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        class="is_live"
        @click="LinkAct(exInfo.activityLiving)"
        v-if="exInfo.isLiving"
      >
        <div class="top">
          <i class="iconfont icon-zhibobofangshexiangjiguankanmianxing"></i
          >正在直播中...
        </div>
        <img
          v-if="
            exInfo.activityLiving && exInfo.activityLiving.liveBackgroundUrl
          "
          :src="exInfo.activityLiving.liveBackgroundUrl"
        />
        <img v-else src="@/assets/img/empty.png" />
        <p class="ellipsis1">{{ exInfo.activityLiving.title }}</p>
        <p class="ellipsis1 time">{{ exInfo.activityLiving.liveTime }}</p>
      </div>-->
    </div>
    <orderModal :exhibitorId="exhibitorId" ref="orderModal"></orderModal>
    <chatTalkUser :exhibitorId="exhibitorId" :isAlone="true" v-if="talkShow"></chatTalkUser>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import evaluate from "@components/evaluate/index.vue";
import gSwiper from "@components/gSwiper/gSwiper.vue";
import orderModal from "@components/orderModal/index.vue";
import videoPlayer from "./components/videoPlayer";
import proList from "./components/product-list";
import actList from "./components/act-list";
import mienList from "./components/mien-list";
import needList from "./components/need-list";
import gSwiperCommon from "@components/gSwiperCommon/gSwiperCommon.vue";
import chatTalkUser from "@components/chat-talk-user/index.vue";
import { mapActions, mapGetters } from "vuex";
import url from "@/utils/url";
import moment from "moment";
export default {
  name: "index",
  data () {
    return {
      url: url,
      // exInfo: {
      //   company: {},
      // },
      exInfo: {
        company: {},
        talkCount: '-',
        appointmentNumber: '-',
        viewCount: '-',
        userCount: '-',
        thinkCount: '-',
        productNumber: '-',
        activityNumber: '-'
      },
      isLike: false,
      isFollower: false,
      newsList: [],
      talkShow: false,
    };
  },
  components: {
    evaluate,
    videoPlayer,
    proList,
    actList,
    mienList,
    orderModal,
    gSwiper,
    gSwiperCommon,
    needList,
    chatTalkUser
  },
  computed: {
    ...mapGetters({
      exhibitionInfo: "getExhibitionInfo",
      exhibitionId: "getExhibitionId",
      user: "getUser",
      myAdvisorySetting: 'getMyAdvisorySetting',
      exhibitionConfig: "getExhibitionConfig",
    }),
    exhibitorId () {
      return this.$route.params.id;
    },
    isRegOk () {
      return (
        this.user &&
        this.user.inMember &&
        this.user.inMember.userRole &&
        this.user.inMember.userRole != "VISITOR" &&
        this.user.inMember.userRole != "Visitor"
      );
    },
    isServer () {
      let ser = false;
      if (this.user && this.user.inMember && this.user.inMember.companyUsers && this.user.inMember.companyUsers.id) {
        ser = true;
      }
      return ser
    },
    // 判断展商Logo是方形还是圆形
    exhibitorWebLogo () {
      // 方形为true 圆形为false
      return this.exhibitionConfig && this.exhibitionConfig.display ? this.exhibitionConfig.display.exhibitorWebLogo === "SQUARE" : false;
    },
  },
  created () {
    if (!this.exhibitionId) {
      this.$store.commit("setExhibitionId", this.$route.query.exhibitionId);
    }
    this.getInfo();
    this.getNews();
    if (this.user.userId) {
      this.getFollowAndLikeState();
    }
    if (this.exhibitorId) {
      let opt = {
        exhibitionId: this.exhibitionId,
        target: "exhibitor",
        targetId: this.exhibitorId,
      };
      if (this.userId) {
        opt.userId = this.userId;
      }
      this.addView2(opt);
    }
  },
  mounted () {
    this.$Bus.$off('talkAloneClose')
    this.$Bus.$on('talkAloneClose', () => {
      this.talkShow = false;
    })
    setTimeout(() => {
      this.creatQrCode("qrCodeUrl");
    }, 500)
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      addAllView: "addAllView",
      exLike: "exhibitor/exLike",
      addCompLove: "exhibitor/addCompLove",
      delCompLove: "exhibitor/delCompLove",
      unverifiedDialog: "unverifiedDialog",
      LinkAct: "linkTo/LinkAct",
      // addView: "loginModule/addView",
      getShareConfig: 'share/getShareConfig',
      linkToNewsDetails: 'linkTo/linkToNewsDetails',
      addAppointmentNumber: 'exhibitor/addAppointmentNumber',
      // linkToTour:'linkTo/linkToTour',
      linkToTour: 'linkTo/linkToTourByExhibitorId',
      getUserAdvisorySetting: "roleModule/getUserAdvisorySetting"
    }),
    goToNews (e) {
      if (e.externalUrl) {
        window.open(e.externalUrl);
      } else {
        this.linkToNewsDetails(e.id)
      }
    },
    addView2 () {
      let opt = {
        exhibitionId: this.exhibitionId,
        target: 'exhibitor',
        targetId: this.exhibitorId,
      }
      if (this.user && this.user.userId) {
        opt.userId = this.user.userId;
      }
      this.addAllView(opt)
    },
    ToTour (item) {
         if(!this.enabledBmm){
           this.$Message.info("暂未开启展位图!");
           return
        }
      this.$store.commit('setExhibitor', item)
      this.linkToTour(this.exhibitorId);
    },
    async setShare () {
      let data = await this.getShareConfig({ id: this.exhibitionId, name: 'share.exhibitor.config' });
      let share = {
        url: location.href,
        title: this.exInfo.company.name || '展商邀请函',
        desc: '',
        summary: '',
        pics: this.exInfo.company.logo || '',
      };
      if (data) {
        let dataJson = JSON.parse(data);
        if (dataJson.content) {
          dataJson.content = dataJson.content.replace(/【公司】/g, this.exInfo.company.name)
          dataJson.content = dataJson.content.replace(/【展位号】/g, this.exInfo.boothInfo)
          share.title = dataJson.content
        }
        if (dataJson.des) {
          dataJson.des = dataJson.des.replace(/【公司】/g, this.exInfo.company.name)
          dataJson.des = dataJson.des.replace(/【展位号】/g, this.exInfo.boothInfo)
          share.desc = dataJson.des;
          share.summary = dataJson.des;
        }
      }
      console.log(share, '展商得分享')
      this.$store.commit('setShare', share);
    },

    async getFollowAndLikeState () {
      let query = `
        query{
          exhibitorFollowerQuery{
            isrFollow(exhibitorId:"${this.exhibitorId}",userId:"${this.user.userId}")
          }
          exhibitorLikeQuery{
            isLike(exhibitorId:"${this.exhibitorId}",userId:"${this.user.userId}")
          }
        }
      `;
      let opt = {
        query: query,
        variables: {},
      };

      let data = await this.graphqlPost(opt);
      let res = JSON.parse(JSON.stringify(data.data));
      this.isFollower = res.exhibitorFollowerQuery.isrFollow;
      this.isLike = res.exhibitorLikeQuery.isLike;
    },
    //创建二维码
    creatQrCode (ref) {
      let url = `https://${this.url.mobile}/exhibitor/` + this.exhibitorId;
      this.qrcode = new QRCode(this.$refs[ref], {
        text: url, // 需要转换为二维码的内容
        width: 220,
        height: 220,
        colorDark: "#000",
        colorLight: "#fff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    async onLineTalk () {
      if (!this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      if (this.user && this.user.inMember.isBlacklist) {
        this.$Message.warning('您已被屏蔽');
        return
      }
      if (!this.getRoleHandle('L1ExShow-L2IM-L3IM')) {
        return
      }
      if (this.user && this.isServer && this.user.inMember.exhibitorId == this.exhibitorId) {
        this.$Message.warning('无法给自己公司发起洽谈')
        return
      }
      if (this.exInfo && this.exInfo.id) {
        this.addAppointmentNumber(this.exInfo.id);
      }
      this.$Bus.$emit('talkClose');
      this.talkShow = true;
      // this.$Message.warning('暂未开放')
    },
    //模态框打开
    async orderOpen () {
      if (!this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      if (this.user && this.user.inMember.isBlacklist) {
        this.$Message.warning('您已被屏蔽');
        return
      }
      if (!this.getRoleHandle('L1Operate-L2YuYue-L3Publish')) {
        return
      }
      if (!this.myAdvisorySetting || this.myAdvisorySetting.appointmentDayNumber == 0) {
        await this.getUserAdvisorySetting()
      }
      if (this.myAdvisorySetting.appointmentDayNumber > 0) {
        this.$refs.orderModal.open();
      } else {
        this.$Message.warning('您今天的洽谈次数已达上限')
      }
    },
    wCount (value) {
      //过万处理
      let num = value;
      if (value > 9999) {
        //大于9999显示x.xx万
        num = Math.floor(value / 100) / 100 + "w";
      } else {
        num = value;
      }
      return num;
    },
    getCate (arr) {
      let str = "";
      if (arr && arr[0]) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] && arr[i].Value) {
            str += str ? "," + arr[i].Value : arr[i].Value;
          }
        }
        return str;
      } else {
        return "";
      }
    },
    async getInfo () {
      let query = `#graphql
        query{
          exhibitorQuery{
            get(id:"${this.exhibitorId}"){
						banner	      #主图海报
            id
            video	      	#主图视频
            objectives    #参展目标
            customerServiceIsAppointmentNumber
            appointmentNumber
            activityLiving{
              acvitityType
              id
              liveAuditState
              liveBackgroundUrl
              externalLink
              title
              hasLive
              liveStartTime
              liveEndTime
            }
            activityNumber
            summaryZh   #参展公司简介
            objectiveTags #参展目标标签 多个按;隔开
            userCount 	  #浏览人数
            thinkCount    #注册人数
						shareCount    #分享次数
						followCount   #关注人数
            likeCount	    #点赞数
						advisoryCount #咨询人数
            appointmentCount #预约数量
            talkCount #洽谈
            activityCount #活动数
            evaluateCount #评价数
            viewCount
            boothInfo
            productNumber
            exhibition{
              banners{
                covers
              }
              banner
              logo
            }
            exhibitionId
            businesses(num:3){
              isSample
            }
            category #产品类别
            evaluateTags{ #评价标签
              evaluateCount #评价数
              name
            }  
            proCount      #产品数量
            continuouNumber #连续参展年份
            isLiving      #是否正在直播
            compId        #公司id 
            company{      #公司信息
              address
              id
              name
              logo
              region      #地区
              banners     #banner
              videos      #视频
              country
              province
              city
              website     
              email       
            }
              }
            }
        }
      `;
      let opt = {
        query: query,
        variables: {},
      };
      let res = await this.graphqlPost(opt);
      console.log(res, "exinfo res");
      let data = JSON.parse(JSON.stringify(res.data.exhibitorQuery.get));
      if (!data) return;
      if (data.company.videos && data.company.videos.indexOf("{") !== -1) {
        data.company.videos = JSON.parse(data.company.videos);
      }
      if (data.company.banners) {
        data.company.banners = data.company.banners.split(";");
      }
      if (data.category && data.category.indexOf("[") !== -1) {
        let cateArr = JSON.parse(data.category);
        data.category = this.getCate(cateArr);
      }
      data.evaluateCount = this.wCount(data.evaluateCount); //评价数
      for (let i = 0; i < data.evaluateTags.length; i++) {
        data.evaluateTags.evaluateCount = this.wCount(
          data.evaluateTags.evaluateCount
        );
      }
      if (data.objectiveTags) {
        data.objectiveTags = data.objectiveTags.split(";");
      }
      if (data.company.region) {
        let arr = data.company.region.split("/");
        data.company.regionTxt =
          arr[arr.length - 2] + "·" + arr[arr.length - 1];
      } else {
        data.company.regionTxt = "";
      }
      if (data.isLiving) {
        data.activityLiving.liveTime =
          moment(data.activityLiving.liveStartTime).format("YYYY/MM/DD HH:mm") +
          "  " +
          moment(data.activityLiving.liveEndTime).format("HH:mm");
      }
      this.exInfo = data;
      console.log(this.exInfo, 'exinfofofof')
      this.setShare();
      document.title = data.company
        ? `${data.company.name + '-'}${this.exhibitionInfo.name}`
        : "展商秀";
    },
    async getNews () {
      let query = `
        query($query:QueryInput!){
          articleQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items{
                  articleType
                  author
                  authorId
                  commentCount
                  content
                  covers
                  createAt
                  externalUrl
                  favoriteCount
                  id
                  introduction
                  itemId
                  keywords
                  likeCount
                  metadata
                  publishAt
                  shareCount
                  status
                  subtitle
                  title
                  updateAt
                  userCount
                  viewCount
                }
              }
            }
        }
      `;
      let where = {
        Equal: {
          n: "authorId",
          v: this.exhibitorId,
        },
        Equal_isdeleted: {
          n: "isDeleted",
          v: false,
        },
      };
      let order = [
        { N: "topic", v: 0 },
        { N: "toppingIndex", v: 0 },
        { N: "publishAt", v: 0 },
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: 1,
            size: 3,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(JSON.stringify(data.data.articleQuery.query));
      res.items.forEach(e => {
        if (e.covers) {
          e.covers = JSON.parse(e.covers);
          e.publishAt = moment(e.publishAt).format('YYYY/MM/DD')
        }
      });
      this.newsList = res.items;
    },
    async exFollowerClick () {
      if (!this.user.userId) {
        this.unverifiedDialog(this);
        return;
      }
      if (this.isFollower) {
        // 取消关注
        let body = {
          exhibitorId: this.exhibitorId,
          userId: this.user.userId,
        };
        let result = await this.delCompLove(body);
        if (result.result) {
          this.isFollower = false;
          this.exInfo.followCount -= 1;
          this.$Message.success("取消关注");
        } else {
          this.$Message.error(result.message);
        }
      } else {
        // 展商关注
        let userInfo = {
          name: this.user.name,
          avatarUrl: this.user.avatarUrl,
          nickName: this.user.nickName,
        };
        let opt = {
          compId: this.exInfo.compId,
          exhibitionId: this.exhibitionId,
          exhibitorId: this.exhibitorId,
          userId: this.user.userId,
          userInfo: JSON.stringify(userInfo),
        };
        let result = await this.addCompLove(opt);
        if (result.result) {
          this.isFollower = true;
          this.exInfo.followCount += 1;
          this.$Message.success("关注成功");
        } else {
          this.$Message.error(result.message);
        }
      }
    },
    async exLikeClick () {
      if (!this.user.userId) {
        this.unverifiedDialog(this);
        return;
      }
      if (this.isLike) {
        this.$Message.info("您已经点过赞啦");
        return;
      }
      let userInfo = {
        name: this.user.nickName || this.user.name || "",
        avatarUrl: this.user.avatarUrl,
        nickName: this.user.nickName,
      };
      let opt = {
        compId: this.exInfo.compId,
        exhibitionId: this.exhibitionId,
        exhibitorId: this.exhibitorId,
        userId: this.user.userId,
        userInfo: JSON.stringify(userInfo),
      };
      let res = await this.exLike(opt);
      if (res.result) {
        this.isLike = true;
        this.exInfo.likeCount += 1;
        this.$Message.success("点赞成功");
      } else {
        this.$Message.error(res.message || "点赞失败");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.content {
  width: 1200px;
  margin: 0 auto;
}
.head-banner {
  .ex_banner {
    height: 672px;
  }
  .banner {
    width: 1200px;
    margin: 20px 0;
  }
  .is_live {
    position: fixed;
    top: 237px;
    right: 150px;
    background: #fff;
    width: 190px;
    height: 310px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px;
    cursor: pointer;
    .top {
      color: #ff5e00;
      margin-bottom: 8px;
      i {
        font-size: 14px;
        margin-right: 6px;
      }
    }
    img {
      display: block;
      width: 150px;
      height: 200px;
      border-radius: 3px;
    }
    p {
      font-size: 14px;
      margin-top: 8px;
      &.time {
        margin-top: 0;
        font-size: 12px;
      }
    }
  }
}
.company-title {
  width: 1200px;
  height: 200px;
  position: relative;
  border: solid 1px #ededed;
  &-content {
    height: 200px;
    align-items: center;
    padding: 20px;
    display: flex;
    background-color: #fff;
    .logo {
      width: 160px;
      height: 160px;
      border: solid 1px #e6e6e6;
      margin-right: 20px;
      flex-shrink: 0;
    }
  }
  &-center {
    max-width: calc(100% - 180px - 220px);
    .top {
      display: flex;
      align-items: center;
      max-width: 100%;
      .name {
        color: #333;
        font-size: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: bold;
      }
      .address {
        // width: 79px;
        flex-shrink: 0;
        height: 20px;
        background-color: #ddeeff;
        @include font_color(#1890ff);
        display: flex;
        justify-content: center;
        margin-left: 14px;
        padding: 0 11px;
      }
      .product-renzheng {
        height: 22px;
        background-color: #fff;
        border: solid 1px #ffe7d9;
        margin-left: 10px;
        display: flex;
        align-items: center;
        background-color: #ffe7d9;
        div {
          background-color: #fff;
          display: flex;
          align-items: center;
          width: 20px;
          height: 20px;
          justify-content: center;
        }
        span {
          display: inline-block;
          margin: 0 6px;
          font-size: 12px;
          color: #ff5e00;
        }
      }
    }
    .center {
      height: 16px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      .iconfont {
        font-size: 14px;
        margin-right: 7px;
        &.icon-dizhi {
          color: #fe781d;
        }
        &.icon-biaoqian1 {
          @include font_color(#1890ff);
        }
      }
      .addr {
        margin-right: 19px;
      }
    }
    .bottom {
      width: 525px;
      height: 70px;
      background-color: #f7f7f7;
      border-radius: 5px;
      margin-top: 20px;
      .tongji {
        padding: 16px 0 16px 28px;
        display: flex;
        align-items: center;
        height: 70px;
        .span1 {
          font-size: 18px;
          color: #ff5c00;
        }
        .span2 {
          font-size: 14px;
          // @include font_color(#1890ff);
        }
        .fenge {
          width: 1px;
          height: 26px;
          background-color: #dddddd;
          margin: 0 20px;
        }
      }
    }
  }
  .dianzan {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;

    .icon_wrap {
      color: #bfbfbf;
      &:hover,
      &.ok {
        color: #ff5c00;
        span {
          color: #ff5c00;
        }
      }
      span {
        font-size: 12px;
        color: #666;
      }
      cursor: pointer;
      display: flex;
      align-items: center;
      .iconfont {
        margin-right: 5px;
      }
    }
    .icon1 {
      margin-right: 20px;
    }
  }
  .bottom-btn {
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
  }
}
.introduction {
  padding: 20px;
  p {
    font-size: 14px;
    color: #333;
  }
  &-bottom {
    background-color: #f7f7f7;
    border-radius: 5px;
    margin-top: 20px;
    .introduction-bottom-category {
      padding: 20px;
    }
    .top {
      display: flex;
      flex-wrap: wrap;
      span {
        padding: 2px 8px;
        border: 1px solid #ff5e00;
        // @include border_color(#ff5e00);
        color: #ff5e00;
        border-radius: 3px;
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 14px;
      }
    }
  }
}

.company-content {
  padding: 20px;
  .company-name {
    font-size: 14px;
    color: #333;
  }
  .company-address {
    font-size: 14px;
    color: #666;
    margin-top: 20px;
  }
}
.company-video {
  padding: 20px;
}
.news {
  &-item {
    padding: 20px;
  }
  &-top {
    &-p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 10px;
    }
  }
  &-banner {
    width: 270px;
    height: 162px;
  }
  .time {
    font-size: 12px;
    color: #999999;
    margin-top: 10px;
  }
  &-bottom {
    margin-top: 16px;
  }
  &-list {
    border-top: 1px solid #ededed;
    display: flex;
    padding-top: 14px;
    margin-top: 14px;
    img {
      width: 134px;
      height: 80px;
    }
    image {
      width: 134px;
      height: 80px;
    }
    .right-box {
      width: calc(100% - 148px);
    }
    p {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      display: -webkit-box;
    }
  }
}
.code {
  width: 310px;
  height: 310px;
  background-color: #fff;
  border-radius: 2px;
  border: solid 1px #ededed;
  text-align: center;
  padding-top: 26px;
  span {
    font-size: 16px;
    margin-top: 20px;
    color: #333;
    display: inline-block;
  }
}

.round {
  border-radius: 50%;
}
</style>
